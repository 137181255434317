import * as React from "react"

import { graphql } from "gatsby"
import PostList from "../components/PostList"
import { IPost } from "../types/post"
import SEO from "../components/SEO"

interface PostsPageProps {
    data: {
        posts: { edges: { node: IPost }[] }
    }
}

export default function PostsPage({
    data: {
        posts: { edges },
    },
}: PostsPageProps) {
    return (
        <>
            <SEO title="Blog" />
            <PostList posts={edges.map((x) => x.node)} />
        </>
    )
}

export const pageQuery = graphql`
    query {
        posts: allMdx(
            filter: {
                frontmatter: { type: { eq: "post" }, published: { eq: true } }
            }
            sort: { order: DESC, fields: frontmatter___date }
        ) {
            edges {
                node {
                    id
                    excerpt(truncate: true, pruneLength: 140)
                    frontmatter {
                        author
                        date(fromNow: true)
                        tags
                        title
                    }
                    timeToRead
                    headings(depth: h1) {
                        value
                        depth
                    }
                    slug
                }
            }
        }
    }
`
